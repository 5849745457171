<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'testimonial-person-graph-tab',
  data() {
    return {
      tab: 'graph1',
      tabItems: [
        { name: 'graph1', icon: 'query_stats', label: '부서별 그래프', component: () => import(`${'./testimonialPersonGraph1.vue'}`) },
        // { name: 'graph2', icon: 'bar_chart', label: '질환별 그래프', component: () => import(`${'./testimonialPersonGraph2.vue'}`) },
        { name: 'graph3', icon: 'format_align_left', label: '유해인자별 그래프', component: () => import(`${'./testimonialPersonGraph3.vue'}`) },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      completeFlagItems: [
        { code: null, codeName: '전체' },
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '완료' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
    },
  }
};
</script>
